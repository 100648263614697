<template>
    <div>
        <v-app>
            <!-- Loader Web -->
            <div v-if="skeletonLoaderFlag" class="loader-web grey-background">
                <v-skeleton-loader elevation="0" type="image" height="120" class="mb-6"></v-skeleton-loader>

                <div class="container mb-6">
                    <div class="pa-6 rounded-lg">
                        <div class="row">
                            <div class="mr-2 col-3">
                                <div class="pa-4 mb-4 rounded-lg white-background">
                                    <v-skeleton-loader elevation="0" type="image" height="100"></v-skeleton-loader>
                                </div>

                                <div class="pa-4 rounded-lg white-background">
                                    <v-skeleton-loader elevation="0" type="image"></v-skeleton-loader>
                                </div>
                            </div>

                            <div class="ml-2 col-8">
                                <div class="pa-4 rounded-lg white-background">
                                    <v-skeleton-loader elevation="0" type="heading"></v-skeleton-loader>

                                    <div class="pt-6 pb-2 d-flex flex-wrap">
                                        <div>
                                            <v-skeleton-loader elevation="0" type="image" width="180" class="px-2"></v-skeleton-loader>
                                            <v-skeleton-loader elevation="0" type="heading" class="pa-4"></v-skeleton-loader>
                                        </div>

                                        <div>
                                            <v-skeleton-loader elevation="0" type="image" width="180" class="px-2"></v-skeleton-loader>
                                            <v-skeleton-loader elevation="0" type="heading" class="pa-4"></v-skeleton-loader>
                                        </div>

                                        <div>
                                            <v-skeleton-loader elevation="0" type="image" width="180" class="px-2"></v-skeleton-loader>
                                            <v-skeleton-loader elevation="0" type="heading" class="pa-4"></v-skeleton-loader>
                                        </div>

                                        <div>
                                            <v-skeleton-loader elevation="0" type="image" width="180" class="px-2"></v-skeleton-loader>
                                            <v-skeleton-loader elevation="0" type="heading" class="pa-4"></v-skeleton-loader>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <v-divider></v-divider>
            </div>

            <!-- Loader Mobile -->
            <div v-if="skeletonLoaderFlag" class="loader-mobile">
                <category-header-mobile page_name="Categories" :icon_show_status="{ sort_icon: false, filter_icon: false }"></category-header-mobile>
                <v-divider></v-divider>

                <div class="pa-4">
                    <v-skeleton-loader elevation="0" type="heading" class="py-4"></v-skeleton-loader>

                    <div class="d-flex flex-wrap">
                        <div>
                            <v-skeleton-loader elevation="0" type="image" width="150" height="150" class="px-2"></v-skeleton-loader>
                            <v-skeleton-loader elevation="0" type="heading" class="pa-4 d-flex justify-center align-center"></v-skeleton-loader>
                        </div>

                        <div>
                            <v-skeleton-loader elevation="0" type="image" width="150" height="150" class="px-2"></v-skeleton-loader>
                            <v-skeleton-loader elevation="0" type="heading" class="pa-4 d-flex justify-center align-center"></v-skeleton-loader>
                        </div>

                        <div>
                            <v-skeleton-loader elevation="0" type="image" width="150" height="150" class="px-2"></v-skeleton-loader>
                            <v-skeleton-loader elevation="0" type="heading" class="pa-4 d-flex justify-center align-center"></v-skeleton-loader>
                        </div>

                        <div>
                            <v-skeleton-loader elevation="0" type="image" width="150" height="150" class="px-2"></v-skeleton-loader>
                            <v-skeleton-loader elevation="0" type="heading" class="pa-4 d-flex justify-center align-center"></v-skeleton-loader>
                        </div>
                    </div>
                </div>
            </div>
            
            <div v-if="!skeletonLoaderFlag && !(this.screenWidth <= 600) " class="catalog-web-component">
                <catalog-web-page @refreshData="refreshData" :key="webComponentKey" :catalog_data="catalogData" :is_user_logged_in="isUserLoggedIn" :axiosflag="axiosflag" ></catalog-web-page>
            </div>

            <div v-if="!skeletonLoaderFlag" class="container catalog-mobile-component">
                <catalog-mobile-page @toggleBottomNavigation="toggleBottomNavigation" @refreshData="refreshData" :key="mobileComponentKey" :catalog_data="catalogData"></catalog-mobile-page>
            </div>

            <!-- Footer (Web) -->
            <div class="pa-4 footer-segment">
                <common-home-footer></common-home-footer>
            </div>

            <!-- Bottom Navigation Bar (Mobile) -->
            <div v-if="showBottomNavigationFlag" class="bottom-navbar-segment mobile-bottom-nav-bar">
                <bottom-navigation-bar highlight_icon="pharmacy"></bottom-navigation-bar>
            </div>
        </v-app>
    </div>
</template>

<script>
import { axios_auth_instance_epharmacy_customer } from '../../../utils/axios_utils.js';
import axios from "axios";
export default {
    name: 'CatalogPage', 
    components: {
        'common-home-header': () => import('../../../components/navigationComponents/commonHomeHeader.vue'),
        'header-tabs-component': () => import('../../../components/navigationComponents/headerTabsComponent.vue'),
        'catalog-web-page': () => import('../../../components/ePharmacyComponents/webPageComponents/catalogPage.vue'),
        'category-header-mobile': () => import('../../../components/navigationComponents/categoryHeaderMobile.vue'),
        'catalog-mobile-page': () => import('../../../components/ePharmacyComponents/mobilePageComponents/catalogPage.vue'),
        'common-home-footer': () => import('../../../components/navigationComponents/commonHomeFooter.vue'),
        'bottom-navigation-bar': () => import('../../../components/navigationComponents/bottomNavigationBar.vue')
    },
    data() {
        return {
            skeletonLoaderFlag: true,
            isUserLoggedIn: false,
            showBottomNavigationFlag: true,
            webComponentKey: 0,
            mobileComponentKey: 0,
            catalog_name: null,
            catalogData: {
                category_id: null,
                category_name: null,
                catalog_name: null,
                subcategories: [],
                products: [],
                exclusiveStartKey: null,
            },
            getSubCategoriesResponse: null,
            getCatalogProductsResponse: null,
            axiosflag: false,
            screenWidth: window.innerWidth,
            error_enable: false,
            code_error_enable: false,
            auth_error_enable: false,
            token: null
        }
    },
    mounted() {
        // Fetch URL Params.
        if (window.xm) {
            this.token = localStorage.getItem('customerToken');
        } else {
            this.token = this.$cookies.get('customerToken');
        }
        this.screenWidth = window.innerWidth;
        this.catalog_name = this.$route.params.catalog_name;
        this.category_id = this.$route.params.category_id;

        this.catalogData.category_id = this.category_id;

        // Fetch subcategories data from getSubCategoriesOfCategory API and products data.
        let sortOrder = {
            sortField: 'alphabetical',
            sortValue: 'AToZ'
        };
        this.refreshData(sortOrder, [this.category_id]);
        this.userPageViewTable();
    },
    methods: {
        userPageViewTable() {
            if (this.token === null) {
                var userPageViewTableBody = {
                    pageName: 'catalogPage',
                    typeOfUser: 'CUSTOMER'
                }
            } else {
                var userPageViewTableBody = {
                    pageName: 'catalogPage',
                    typeOfUser: 'CUSTOMER',
                    token: this.token
                }
            }
            axios.post(process.env.VUE_APP_BACKEND_URL+"/userPageViewTable", userPageViewTableBody)
            .catch((userPageViewTableError) => {
                console.log('[Error][userPageTableError] Error in adding userPageViewTable', userPageViewTableError);
            });
        },
        refreshData(sortOrder, categories) {
            this.skeletonLoaderFlag = true;

            if (this.catalog_name == 'categories') {
                this.getSubCategories();
            }

            this.getCatalogProducts(sortOrder, categories);
        },
        async getSubCategories() {
            await axios_auth_instance_epharmacy_customer.get('/categories/subcategories/' + this.category_id).then((getSubCategoriesOfCategoryResponse) => {
                this.catalogData.category_name = getSubCategoriesOfCategoryResponse.data.category_data.category_name;
                this.catalogData.subcategories = getSubCategoriesOfCategoryResponse.data.data;
                this.webComponentKey++;
                this.mobileComponentKey++;

                if (this.getCatalogProductsResponse) {
                    this.skeletonLoaderFlag = false;
                } else {
                    this.getSubCategoriesResponse = true;
                }
                if (window.xm){
                    document.title = '';
                } else {
                    document.title = this.catalogData.category_name ? this.catalogData.category_name : 'Category';

                }
            }).catch((getSubCategoriesOfCategoryError) => {
                console.log('Error fetching subcategories data: ', getSubCategoriesOfCategoryError);
                this.error_enable = true;
                return true;
            });
        },
        getCatalogProducts(sortOrder, categories = [this.category_id]) {
            let getAvailableProductsByCategoriesRequest = {
                categoriesList: this.catalog_name == 'categories' ? categories : null,
                collectionList: this.catalog_name == 'collections' ? categories : null,
                minAmount: 0,
                maxAmount: 150000
            };

            axios_auth_instance_epharmacy_customer.get('/products/' + this.catalog_name + '/', { params: getAvailableProductsByCategoriesRequest }).then((getAvailableProductsByCategoriesResponse) => {
                // Login Check.
                this.isUserLoggedIn = getAvailableProductsByCategoriesResponse.data.authenticationStatus == 401 ? false : true;
                this.catalogData.catalog_name = this.catalog_name;
                this.catalogData.products = getAvailableProductsByCategoriesResponse.data.data;
                this.catalogData.exclusiveStartKey = getAvailableProductsByCategoriesResponse.data.exclusiveStartKey;
                this.webComponentKey++;
                this.mobileComponentKey++;

                if (this.catalog_name == 'categories') {
                    if (this.getSubCategoriesResponse) {
                        this.skeletonLoaderFlag = false;
                    } else {
                        this.getCatalogProductsResponse = true;
                    }
                } else {
                    this.catalogData.category_name = getAvailableProductsByCategoriesResponse.data.collection_data.collection_name;
                    this.skeletonLoaderFlag = false;
                }
                this.axiosflag = true;
            }).catch((getAvailableProductsByCategoriesError) => {
                console.log('Error fetching catalog page products data: ', getAvailableProductsByCategoriesError);
                this.error_enable = true;
                return true;
            });
        },
        toggleBottomNavigation(showFlag) {
            this.showBottomNavigationFlag = showFlag;
        }
    }
}
</script>

<style lang="scss">
@import '../../../scss/classes.scss';

.loader-web, .footer-segment, .catalog-web-component {
    @media (max-width: 600px) {
        display: none;
    }
}

.loader-mobile {
    @media (min-width: 601px) and (max-width: 1264px) {
        display: none;
    }

    @media (min-width: 1265px) {
        display: none;
    }
}

.catalog-mobile-component {
    height: 75vh;

    @media (min-width: 601px) and (max-width: 1264px) {
        display: none;
    }

    @media (min-width: 1265px) {
        display: none;
    }
}
.mobile-bottom-nav-bar {
	@media (max-width: 600px) {
        
	}

	@media (min-width: 601px) and (max-width: 1264px) {
		display: none !important;
	}

	@media (min-width: 1265px) {
		display: none !important;
	}
}
</style>